import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import AdminForm from './components/AdminForm.js';
import BlogPage from './components/blogpage.js'
import './App.css';
<Route path="/" element={<MainContent />} />

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/admin" element={<AdminForm />} />
             {/* Route for blog page without productId */}
        <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:productId" element={<BlogPage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
