import React from 'react';

function Header() {
  return (
    <header className="header">
      JConnector.io
      {/* Add logo or social icons here */}
    </header>
  );
}

export default Header;
